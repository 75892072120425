body {
  background-color: #f8f9fa;
}

.container {
  margin-top: 20px;
}

.navbar {
  margin-bottom: 20px;
}

.card {
  margin-bottom: 20px;
}



/* App.css */

.container {
  max-width: 800px;
  margin: auto;
}

button {
  margin-top: 10px;
}
